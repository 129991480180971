<!--  -->
<template>
  <div class="layout">
    <div class="header">
      <router-link to="/h5">
        <img src="@/assets/h5_image/logo-title.png" alt="" class="logo" />
      </router-link>
      <div class="phone-box">
        <img src="@/assets/image/phone_blank_icon.png" class="phone" alt="" />
        <p class="text"><strong>400-969-6261</strong></p>
      </div>
    </div>

    <div class="banner">
      <img
        src="@/assets/h5_image/layout-banner.png"
        alt=""
        class="banner-img"
      />
    </div>
    <div class="body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "layout",
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="scss" scoped>
.layout {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px;
    .logo {
      width: 50vw;
    }
    .phone-box {
      font-size: 14px;
      display: flex;
      align-items: center;
      .phone {
        width: 20px;
        height: 20px;
      }
    }
  }
  .banner {
    display: flex;
    .banner-img {
      width: 100%;
      // height: 300px;
      // object-fit: contain;
    }
  }
}
</style>
